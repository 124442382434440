import React from 'react';
import { graphql, Link } from 'gatsby';

import hero from '../images/investments/hero.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import Landing from '../components/website/Landing';
import Video from '../components/website/Video';
import InvestmentsFaq from '../components/website/Faq';
import ContentSection from '../components/website/Section';
import { DownloadButtonWrapper } from '../styles';

const Investing = ({ data }) => {
	const metaDescription = data.allGoogleContentNewSheet.edges.find(
		({ node: { position } }) => position === 'landing-meta-description'
	).node.text;

	const sectionContent = section =>
		data.allGoogleInvestingSheet.edges.filter(({ node: { position } }) => position?.includes(`section${section}`));

	const footerText = () =>
		data.allGoogleInvestingSheet.edges.find(({ node: { position } }) => position?.includes('footer-text'));

	return (
		<Layout footerText={footerText()?.node?.text ?? null}>
			<MetaData
				title="Envel - Investments"
				description={`Envel Investments - ${metaDescription}`}
				image="images/social/socialInvestments.png"
			/>

			<Landing content={sectionContent(0)} num="0" section="viewed" notFullPage={true} image={hero} />

			<br />
			<Video content={sectionContent(1)} num="1" />

			<ContentSection
				content={sectionContent(2)}
				num="2"
				animationName="Investments"
				animationFile="/flares/investments.flr"
				notFullPage={true}
				reverse={true}
				showButton={false}
			/>
			<ContentSection
				content={sectionContent(3)}
				num="3"
				animationName="WHY CHOOSE ENVEL? section"
				animationFile="/flares/investments_portfolio.flr"
				notFullPage={true}
				showButton={false}
			/>
			<ContentSection
				content={sectionContent(4)}
				num="4"
				animationName="logo reveal investments"
				animationFile="/flares/investments_companies.flr"
				notFullPage={true}
				reverse={true}
				showButton={false}
				buttonComponent={
					<Link to="/investments-merchants">
						<DownloadButtonWrapper className="minWidth permanent" onClick={() => {}}>
							Show merchants
						</DownloadButtonWrapper>
					</Link>
				}
			/>
			<br />

			<InvestmentsFaq content={sectionContent(5)} num="5" />
			<br />
		</Layout>
	);
};

export default Investing;

export const investingQuery = graphql`
	query {
		allGoogleInvestingSheet {
			edges {
				node {
					position
					text
					text1
					text2
					text3
					text4
					text5
				}
			}
		}
		allGoogleContentNewSheet {
			edges {
				node {
					position
					text
				}
			}
		}
	}
`;
