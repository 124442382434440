import React from 'react';
import ReactPlayer from 'react-player';

import { getSectionContent } from '../../utils/general';
import envel from '../../images/investments/investments.mp4';
import { SectionFullVideo, SectionHeader } from '../../styles';

const Video = ({ content, num }) => {
	const title = getSectionContent(num, 'title', content);

	return (
		<>
			{title && (
				<SectionHeader center smaller>
					{title.text}
				</SectionHeader>
			)}
			<br />
			<SectionFullVideo>
				<ReactPlayer id="envel_investments_video" url={envel} playsinline width="100%" height="auto" controls={true} />
			</SectionFullVideo>
		</>
	);
};

export default Video;
