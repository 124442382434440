import React, { useState } from 'react';
import { graphql, navigate, StaticQuery } from 'gatsby';
import SlideToggle from 'react-slide-toggle';

import { getSectionContent } from '../../utils/general';
import {
	SectionHeader,
	FAQTop,
	FAQBody,
	Body,
	FaqContainer,
	FAQTitle,
	FAQArrow,
	SectionSubHeader,
	GradientButton,
	Wrapper,
	FAQRowBG,
} from '../../styles';

const InvestmentsFaq = ({ content, num }) => {
	const [selected, setSelected] = useState({ i: null, j: null });

	const top = getSectionContent(num, 'top', content);
	const title = getSectionContent(num, 'title', content);
	const footer = getSectionContent(num, 'footer', content);

	return (
		<StaticQuery
			query={graphql`
				query {
					allGoogleFaqSheet(filter: { heading: { eq: "Investments" } }) {
						edges {
							node {
								question
								answer
								heading
							}
						}
					}
				}
			`}
			render={data => (
				<>
					{top && <SectionSubHeader center>{top.text}</SectionSubHeader>}
					{title && (
						<SectionHeader center smaller>
							{title.text}
						</SectionHeader>
					)}

					<FaqContainer>
						{data.allGoogleFaqSheet.edges.map(({ node: { question, answer, heading } }, j) => (
							<SlideToggle collapsed={true} key={question}>
								{({ toggle, setCollapsibleElement, toggleState }) => {
									if (toggleState === 'EXPANDED' && selected.j !== j) toggle();
									return (
										<FAQRowBG>
											<FAQTop
												hasBG={true}
												onClick={e => {
													setSelected({ j: j });
													toggle();
												}}
											>
												<FAQTitle dangerouslySetInnerHTML={{ __html: question }} />
												<FAQArrow toggle={toggleState} />
											</FAQTop>
											<div ref={setCollapsibleElement}>
												<FAQBody dangerouslySetInnerHTML={{ __html: answer }} />
											</div>
										</FAQRowBG>
									);
								}}
							</SlideToggle>
						))}
					</FaqContainer>

					{footer && (
						<Body center grayScaleFaded>
							{footer.text}
						</Body>
					)}
					<br />
					<Wrapper row justifyCenter>
						<a href="mailto:support@envel.ai" target="_blank" rel="noopener noreferrer">
							<GradientButton round onClick={() => {}}>
								Contact support
							</GradientButton>
						</a>
					</Wrapper>
				</>
			)}
		/>
	);
};

export default InvestmentsFaq;
